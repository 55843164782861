<template>
    <v-container fluid>
        <v-form @submit.prevent="saveSecurity">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('security') }}</v-subheader>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-container class="pa-0 px-0">
                                    <ValidationProvider ref="number" rules="required|min:3|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="number" type="text"
                                                      outlined
                                                       :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('number')" color="primary"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-container>
                            </v-col>
                            <v-col cols="12" class="pb-0">
                                <v-container class="pa-0 px-0">
                                    <ValidationProvider ref="name" rules="required|min:3|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="name" type="text"
                                                      outlined
                                                       :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('name')" color="primary"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-container>
                            </v-col>
                            <v-col cols="12"  class="text-center">
                                 Фото водителя
                                <make-photo v-model="photo" :aspect-ratio="(1/1)"/>
                            </v-col>

                        </v-row>
                    <v-card-text>
                        <v-container class="py-0 my-0 pa-0 px-0">
                        <v-progress-linear v-model="progress" :active="loading" class="mx-2"></v-progress-linear>
                        </v-container>
                    </v-card-text>
                    </v-card-text>
                    <v-card-actions>
                        <v-container class="pa-0 px-0">
                        <v-btn block type="submit" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import MakePhoto from '../components/MakePhoto.vue'


export default {
    name: 'AdministratorForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        MakePhoto,
    },
    inject: ['forceRerender'],
    data() {
        return {
            progress: 0,
            create: false,
            number: null,
            name: null,
            id: null,
            loading: false,
            errors: {},
            photo: null,


        }
    },

    methods: {
          async saveSecurity() {
              var _this = this;
              this.progress = 0
              this.loading = true
              var formData = new FormData()

              if (this.number) {
                  formData.append('number', this.number)
              }
              if (this.name) {
                  formData.append('name', this.name)
              }

              if (this.photo) {
                  if (this.photo.length > 250) {
                      var mimeType = this.getMimeType(this.photo)
                      var blob = this.dataURL64toBlob(this.photo)
                      if (mimeType && blob) {
                          formData.append('photo', blob, mimeType)
                      }
                  }
                  else {
                      formData.append('photo', this.photo)
                  }
              }


            // add
            await this.$http
                .post(`partner/security`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('security_has_been_updated'))

                })
                .catch(err => {
                    this.$toastr.error(this.$t('security_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        }
    }
}
</script>
